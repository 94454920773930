import axios from 'axios';

export interface Message {
  type: string;
  ruleId: string;
  message: string;
  index: number;
  line: number;
  column: number;
  severity: number;
}

export interface LintResult {
  isPassed: boolean;
  messages: Message[];
}

export async function lint(text: string): Promise<LintResult> {
  const response = await axios({
    method: 'POST',
    url: `${WIKI_TEXTLINT_FUNCTIONS_URL}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: { text },
  });

  const data = response.data as any;
  const messages = data.results ? data.results.messages : [];

  return { isPassed: !!data.isPassed, messages };
}
