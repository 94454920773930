
import { BModal } from 'bootstrap-vue';
import { Editor } from '@toast-ui/vue-editor';
import ToastUIEditor from '@toast-ui/editor';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import DOMPurify from 'dompurify';

import KamigameVue from '@/KamigameVue';
import {
  WikiPageTitleSearchModal,
  WikiPagePartialSelectModal,
  WikiPageTemplateSelectModal,
  WikiPageKeyboardShortcutHelpModal,
  PartialGenerateCodeModal,
} from '@/components';
import linkAssistModal from './LinkAssistModal.vue';
import ImageUploaderModal from './ImageUploaderModal.vue';

import { KamigameMarkdownEditorConvertor } from './MarkdownEditor/KamigameMarkdownEditorConvertor';
import ToggleViewModeExtension from './MarkdownEditor/ToggleViewModeExt';
import KamigameSaveExtension from './MarkdownEditor/KamigameSaveExt';
import KamigameMarkdownMode from './MarkdownEditor/KamigameMarkdownMode';
import KamigameImageExtension from './MarkdownEditor/KamigameImageExt';
import KamigameLinkAssistExtension from './MarkdownEditor/KamigameLinkAssistExt';
import KamigameWikiPageTemplateInsertExtension from './MarkdownEditor/KamigameWikiPageTemplateInsertExt';
import KamigameWikiPagePartialInsertExtension from './MarkdownEditor/KamigameWikiPagePartialInsertExt';
import KamigameScrollSyncExtension from './MarkdownEditor/KamigameScrollSyncExt';
import KamigameConvertToKamigameOriginExtension from './MarkdownEditor/KamigameConvertToKamigameOriginExt';
import KamigameFullScreenExtension from './MarkdownEditor/KamigameFullScreenExt';
import KamigameLightModeExtension from './MarkdownEditor/KamigameLightModeExt';
import kamigameLoadGametoolExtension from './MarkdownEditor/KamigameLoadGametoolExt';
import KamigamePartialGenerateCode from './MarkdownEditor/KamigamePartialGenerateCode';
import KamigameSearchBar from './MarkdownEditor/KamigameSearchBarExt';

import {
  EVENT_NAME_SHOW_WIKI_PAGE_KEYBOARD_SHORTCUT_HELP_MODAL,
  kamigameWikiPageShowKeyboarShortcutHelpExtention,
} from './MarkdownEditor/KamigameWikiPageShowKeyboarShortcutHelpExt';
import { bindToBlockElement, bindToTableElement } from './MarkdownEditor/editorHighlight';
import { imageUpload } from './MarkdownEditor/ImageUpload';
import { videoUpload } from './MarkdownEditor/VideoUpload';
import { Message } from '@/service/TextLint';

import 'codemirror/lib/codemirror.css';
import '@toast-ui/editor/dist/toastui-editor.css';
import '@toast-ui/editor/dist/i18n/ja-jp';
import WikiSearchBar from './WikiSearchBar.vue';
import WikiEditPage from '@/views/WikiPage/WikiEditPage.vue';
import { components } from '@/api-client/schema';

const LIGHT_RENDER_DELAY = 1500;

const customBold = {
  type: 'button',
  options: {
    name: 'custom-bold',
    className: 'fa fa-bold tui-bold',
    command: 'Bold',
    tooltip: '赤太字',
  },
};

@Component({
  name: 'kamigame-markdown-editor',
  components: {
    editor: Editor,
    'link-assist-modal': linkAssistModal,
    'image-uploader-modal': ImageUploaderModal,
    'kamigame-wiki-page-partial-select-modal': WikiPagePartialSelectModal,
    'kamigame-wiki-partial-generate-code-modal': PartialGenerateCodeModal,
    'kamigame-wiki-page-template-select-modal': WikiPageTemplateSelectModal,
    'kamigame-wiki-page-title-search-modal': WikiPageTitleSearchModal,
    'kamigame-wiki-page-keyboard-shortcut-help-modal': WikiPageKeyboardShortcutHelpModal,
    'kamigame-search-bar': WikiSearchBar,
  },
})
export default class MarkdownEditor extends KamigameVue {
  imageUploadingProgress = false;
  customCssRevision = '';
  isFullScreen = false;
  shortcuts = {};
  isDisplayingSearchBar = false;
  lightModeEnabled: boolean = false;
  onChangeTimeoutID: ReturnType<typeof setTimeout> | null = null;
  onChangeArticleLoadedEventTimeoutID: ReturnType<typeof setTimeout> | null = null;

  linkAssistURLs: {
    title: string;
    url: string;
  }[] = [];

  helperScripts: string[] = [];

  editorContent?: HTMLDivElement;
  editorConvertor?: KamigameMarkdownEditorConvertor;

  editorOptions = {
    hideModeSwitch: true,
    usageStatistics: false,
    language: 'ja',
    toolbarItems: ['heading', customBold, 'strike', 'ul', 'hr', 'table'],
    customHTMLSanitizer: (html: any) => {
      return DOMPurify.sanitize(html, { ALLOWED_TAGS: ['br'] }) || '';
    },
    plugins: [
      KamigameSaveExtension,
      KamigameLinkAssistExtension,
      KamigameImageExtension,
      ToggleViewModeExtension,
      KamigameScrollSyncExtension,
      KamigameWikiPagePartialInsertExtension,
      KamigameWikiPageTemplateInsertExtension,
      KamigamePartialGenerateCode.plugin,
      KamigameConvertToKamigameOriginExtension,
      KamigameSearchBar.plugin,
      KamigameFullScreenExtension,
      KamigameLightModeExtension,
      kamigameLoadGametoolExtension,
      kamigameWikiPageShowKeyboarShortcutHelpExtention,
    ],
    hooks: {
      // prevent ToastUIEditor default image upload
      addImageBlobHook: () => {},
    },
  };

  get editorComponent(): any {
    return this.$refs.tuiEditor;
  }

  get editor(): ToastUIEditor {
    return this.editorComponent.editor;
  }

  get codeMirror(): any {
    return this.editor.getCodeMirror();
  }

  get imageUploadingDisabled() {
    return this.imageUploadingProgress;
  }

  get customCssUrl() {
    return `${WIKI_URL_BASE}/${encodeURIComponent(this.wikiName)}/styles.css?kamigame-custom-css-cache-busting=${
      this.customCssRevision
    }`;
  }

  @Prop()
  disabled!: boolean;

  @Prop()
  value!: string;

  @Prop({
    default: false,
  })
  editsWikiPage!: boolean;

  @Prop({
    default: '',
  })
  description!: string;

  @Prop()
  mode!: 'page' | 'menu' | 'template' | 'partial';

  @Watch('disabled')
  onDisabledUpdated() {
    if (!this.editor) {
      return;
    }

    this.codeMirror.setOption('readOnly', this.disabled);
    const toolbar = this.editorComponent.invoke('getUI').getToolbar();
    if (this.disabled) {
      toolbar.disableAllButton();
    } else {
      toolbar.enableAllButton();
    }
  }

  @Watch('value')
  onValueUpdated() {
    if (this.editorComponent.invoke('getMarkdown') === this.value) {
      return;
    }

    this.editorComponent.invoke('setMarkdown', this.value, false);
    this.onEditorChange();
  }

  @Watch('description')
  onDescriptionUpdated() {
    this.onEditorChange();
  }

  onReplaceText(text: string) {
    this.editor.setMarkdown(text);
    this.onEditorChange();
  }

  onlinkSelected(text: string) {
    this.editor.insertText(text);
    this.onEditorChange();
  }

  clearLintMessages() {
    this.codeMirror.getDoc().clearGutter;
    this.codeMirror.operation(() => {
      this.codeMirror.getDoc().clearGutter('lint');
      this.codeMirror.getDoc().eachLine((l: any) => {
        this.codeMirror.getDoc().removeLineClass(this.codeMirror.getDoc().getLineNumber(l), 'background');
      });
    });
  }

  setLintMessages(messages: Message[]) {
    this.codeMirror.operation(() => {
      const messagesByLine: { [line: number]: Message[] } = {};
      messages.forEach((m) => {
        const line = m.line - 1;
        messagesByLine[line] = messagesByLine[line] || [];
        messagesByLine[line].push(m);
      });

      Object.keys(messagesByLine).forEach((k) => {
        const line = parseInt(k, 10);
        const wrapper = document.createElement('span');
        wrapper.setAttribute('class', 'list-closed');
        wrapper.addEventListener('click', () => {
          const classList = wrapper.classList;
          classList.toggle('list-closed');
          classList.toggle('list-opened');
        });

        const alert = document.createElement('img');
        alert.setAttribute('src', '/img/icon_alert.png');
        alert.setAttribute('class', 'lint-alert');
        wrapper.appendChild(alert);

        const messageList = document.createElement('ul');
        messageList.setAttribute('class', 'gutter-lint-list');

        messagesByLine[line].forEach((m) => {
          const item = document.createElement('li');
          item.setAttribute('class', `gutter-warning`);
          item.classList.add('icon-lint');
          item.textContent = m.message;
          messageList.appendChild(item);

          this.codeMirror.markText({ line, ch: m.column - 1 }, { line, ch: m.column }, { className: 'lint-marker' });
        });
        wrapper.appendChild(messageList);

        this.codeMirror.addLineClass(line, 'background', 'line-warning');
        this.codeMirror.setGutterMarker(line, 'lint', wrapper);
      });
    });
  }

  setUpImageUploader() {
    const editor = document.getElementsByClassName('CodeMirror-scroll')[0];
    editor.addEventListener('dragover', (e: any) => {
      e.dataTransfer.dropEffect = 'copy';
    });
  }

  setUpFileDraggable() {
    const fileDraggable = document.querySelector('.kamigame-file-draggable');
    if (fileDraggable) {
      fileDraggable.addEventListener('kamigame:fileDropped', (e: any) => {
        this.processImageUploader({ title: '', files: e.detail.files });
      });
    }
  }

  setUpKamigameEditorPreview() {
    const container = this.editorComponent.$el.querySelector('.te-md-container');

    const kgPreview = document.createElement('div');
    kgPreview.setAttribute('class', 'te-preview kg-preview');
    kgPreview.id = 'wrapper';
    if (this.$parent && 'createKamigameUrl' in this.$parent) {
      kgPreview.setAttribute(
        'data-path',
        (this.$parent as WikiEditPage).createKamigameUrl()?.replace(KAMIGAME_URL_BASE, '')
      );
    }
    kgPreview.setAttribute('data-game', this.wikiName);

    kgPreview.dataset.previewMode = 'true';

    this.editorContent = document.createElement('div') as HTMLDivElement;
    this.editorContent?.setAttribute('class', 'kamigame-editor-contents');
    const kamigameGenralJsScript = document.createElement('script');
    kamigameGenralJsScript.src = 'https://kamigame.jp/js/general.js';
    this.editorContent?.appendChild(kamigameGenralJsScript);

    kgPreview.appendChild(this.editorContent);
    container.appendChild(kgPreview);

    this.editorConvertor = new KamigameMarkdownEditorConvertor(this.wikiName);
  }

  configurePasteEvent() {
    this.editor.eventManager.events.set('paste', [
      (event: any) => {
        const data: DataTransfer = event.data.clipboardData;
        const images: File[] = Array.from(data.files).filter(
          (file) => file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/gif'
        );
        if (images.length > 0) {
          this.processImageUploader({ title: '', files: images });
        }
      },
      ...this.editor.eventManager.events.get('paste'),
    ]);
  }

  configureCodeMirror() {
    const CodeMirror = this.codeMirror.constructor;
    KamigameMarkdownMode(CodeMirror);
    this.codeMirror.setOption('mode', 'kamigame-markdown');
    this.codeMirror.setOption('gutters', ['lint']);

    this.editor.commandManager.keyMapCommand['CTRL+S'] = 'save-editor-content';
    this.editor.commandManager.keyMapCommand['META+S'] = 'save-editor-content';
    this.editor.commandManager.keyMapCommand['CTRL+I'] = 'show-image-modal';
    this.editor.commandManager.keyMapCommand['META+I'] = 'show-image-modal';
    this.editor.commandManager.keyMapCommand['CTRL+D'] = 'Strike';
    this.editor.commandManager.keyMapCommand['META+D'] = 'Strike';
    this.editor.commandManager.keyMapCommand['CTRL+L'] = 'show-link-modal';
    this.editor.commandManager.keyMapCommand['META+L'] = 'show-link-modal';
    this.editor.commandManager.keyMapCommand['CTRL+P'] = 'show-wiki-page-partial-insert-modal';
    this.editor.commandManager.keyMapCommand['META+P'] = 'show-wiki-page-partial-insert-modal';
    this.editor.commandManager.keyMapCommand['CTRL+F'] = 'toggle-search-bar';
    this.editor.commandManager.keyMapCommand['META+F'] = 'toggle-search-bar';
    this.editor.commandManager.keyMapCommand['CTRL+/'] = EVENT_NAME_SHOW_WIKI_PAGE_KEYBOARD_SHORTCUT_HELP_MODAL;
    this.editor.commandManager.keyMapCommand['META+/'] = EVENT_NAME_SHOW_WIKI_PAGE_KEYBOARD_SHORTCUT_HELP_MODAL;
    this.editor.commandManager.keyMapCommand['SHIFT+CTRL+F'] = 'toggle-full-screen';
    this.editor.commandManager.keyMapCommand['SHIFT+META+F'] = 'toggle-full-screen';
    this.editor.commandManager.keyMapCommand['SHIFT+CTRL+L'] = 'toggle-light-mode';
    this.editor.commandManager.keyMapCommand['SHIFT+META+L'] = 'toggle-light-mode';
    this.editor.commandManager.keyMapCommand['SHIFT+CTRL+G'] = 'load-gametool';
    this.editor.commandManager.keyMapCommand['SHIFT+META+G'] = 'load-gametool';

    const keyMapCommand = this.editor.commandManager.keyMapCommand;
    const shortcuts: { [key: string]: string[] } = {};
    for (const keyName in keyMapCommand) {
      const replacekeyName = keyName.replace(/\+/g, ' + ').replace(/META/g, 'Command');
      shortcuts[keyMapCommand[keyName]]
        ? shortcuts[keyMapCommand[keyName]].push(replacekeyName)
        : (shortcuts[keyMapCommand[keyName]] = [replacekeyName]);
    }
    this.shortcuts = shortcuts;
    this.editor.on('previewRenderAfter', () => {
      if (this.onChangeArticleLoadedEventTimeoutID) {
        window.clearTimeout(this.onChangeArticleLoadedEventTimeoutID);
      }

      const defaultDelay = 500;
      this.onChangeArticleLoadedEventTimeoutID = setTimeout(() => {
        document.dispatchEvent(new Event('kamigame-article-loaded'));
      }, defaultDelay + (this.lightModeEnabled ? LIGHT_RENDER_DELAY : 0));
    });
    this.editor.on('save-editor-content', () => {
      this.$emit('save');
    });
    this.editor.on('show-image-modal', () => {
      const modal = this.$refs.imageUploaderModal as any;
      modal.show();
    });
    this.editor.on(KamigameSearchBar.eventName, () => {
      if (!this.isDisplayingSearchBar) {
        (this.$refs.searchBar as WikiSearchBar).searchFormInputFocus();
      }
      this.isDisplayingSearchBar = !this.isDisplayingSearchBar;
    });
    this.editor.on('show-link-modal', async () => {
      const selectionlinkText = this.codeMirror.getSelection() || '';
      if (this.linkAssistURLs.length === 0) {
        await this.apiClient
          .GET('/admin/wiki/{wikiName}/page/titles', {
            params: {
              path: {
                wikiName: this.wikiName,
              },
              query: {
                excludeRedirected: true,
              },
            },
          })
          .then((r) => {
            if (r.error) {
              throw r.error;
            }
            return r.data;
          })
          .then((response) => {
            if (!response.wikiPageTitles) {
              return;
            }
            this.linkAssistURLs = response.wikiPageTitles.map((wikiPageTitle) => {
              const draftText = wikiPageTitle.published ? '' : '(未公開) ';
              const title =
                draftText + (wikiPageTitle.title || wikiPageTitle.path || `タイトルなし(ID:${wikiPageTitle.id})`);

              const url = wikiPageTitle.path
                ? `/${this.wikiName}/${wikiPageTitle.path}.html`
                : `/${this.wikiName}/page/${wikiPageTitle.id}.html`;
              return {
                title,
                url,
              };
            });
          });
      }
      const modal = this.$refs.linkAssistModal as linkAssistModal;
      modal.show(selectionlinkText);
    });
    this.editor.on('show-wiki-page-template-insert-modal', () => {
      const modal = (this.$refs.wikiPageTemplateSelectModal as KamigameVue).$refs.wikiPageTemplateInsertModal as BModal;
      modal.show();
    });
    this.editor.on('show-wiki-page-partial-insert-modal', () => {
      const modal = (this.$refs.wikiPagePartialSelectModal as KamigameVue).$refs.wikiPagePartialInsertModal as BModal;
      modal.show();
    });
    this.editor.on('toggle-full-screen', () => {
      this.isFullScreen = !this.isFullScreen;
      if (this.isFullScreen) {
        (this.$refs.wikiEditor as Element).classList.add('full-screen');
      } else {
        (this.$refs.wikiEditor as Element).classList.remove('full-screen');
      }
    });
    this.editor.on('toggle-light-mode', (val) => {
      this.lightModeEnabled = val;
    });
    this.editor.on('load-gametool', () => {
      this.previewLoadScript();
    });
    this.editor.on(EVENT_NAME_SHOW_WIKI_PAGE_KEYBOARD_SHORTCUT_HELP_MODAL, () => {
      const modal = (this.$refs.wikiPageKeyboardShortcutHelpModalTemplate as Vue).$refs.modal as BModal;
      modal.show();
    });
    this.editor.on(KamigamePartialGenerateCode.eventName, () => {
      const modal = this.$refs.partialGenerateCodeSelectModal as KamigameVue as PartialGenerateCodeModal;
      modal.show();
    });
  }

  previewLoadScript(): void {
    const scripts = this.helperScripts;
    if (scripts.length === 0) {
      return;
    }
    let helperScriptsElement = document.getElementById('helperScripts');
    if (!helperScriptsElement) {
      helperScriptsElement = document.createElement('div');
      helperScriptsElement.id = 'helperScripts';
      document.body.appendChild(helperScriptsElement);
    }

    while (helperScriptsElement.firstChild) {
      helperScriptsElement.removeChild(helperScriptsElement.firstChild);
    }
    scripts.forEach((script) => {
      const child = document.createElement('script');
      child.src = script;
      helperScriptsElement!.appendChild(child);
    });
  }

  setLightMode(val: boolean) {
    this.editor.exec('toggle-light-mode', val);
  }
  onEditorLoad() {
    document.addEventListener('kamigame-page-partial-fetched', (e) => {
      this.onEditorChange();
    });
  }

  created() {
    this.$store.getters.getWiki(this.wikiName).then((wiki: components['schemas']['v1Wiki']) => {
      const revision = wiki.meta?.find((v) => v.name === 'custom-css-revision');
      if (revision && revision.value) {
        this.customCssRevision = revision.value;
      }
    });
  }

  mounted() {
    this.editor.preview.el.style.display = 'none';

    this.setUpImageUploader();
    this.setUpFileDraggable();
    this.setUpKamigameEditorPreview();
    this.configureCodeMirror();
    this.configurePasteEvent();

    document.getElementsByClassName('CodeMirror-scroll')[0].addEventListener('drop', (event: any) => {
      const imageFiles: File[] = [];
      const mp4Files: File[] = [];
      Array.from(event.dataTransfer.files).forEach((file: any) => {
        if (file.type.indexOf('image') !== -1) {
          imageFiles.push(file);
        }

        if (file.type === 'video/mp4') {
          mp4Files.push(file);
        }
      });
      imageFiles.length > 0 && this.processImageUploader({ title: '', files: imageFiles });
      mp4Files.length > 0 && this.uploadVideos(mp4Files);
    });

    document.dispatchEvent(new Event('kamigame-set-default-preview'));
  }

  async processImageUploader(titleWithFiles: { title: string; files: File[] }): Promise<any> {
    try {
      this.imageUploadingProgress = true;
      await imageUpload(
        this.wikiName,
        this.apiClient,
        this.$store.getters.sessionId,
        this.editor,
        titleWithFiles,
        this.onEditorChange
      );
    } catch (e: any) {
      this.setFlashMessage('danger', '画像のアップロード中にエラーが発生しました。エラー:' + e.message);
    } finally {
      this.imageUploadingProgress = false;
    }
  }

  async uploadVideos(videos: File[]): Promise<any> {
    try {
      this.imageUploadingProgress = true;
      await videoUpload(this.$store.getters.sessionId, this.editor, videos, this.onEditorChange);
    } catch (e: any) {
      this.setFlashMessage('danger', '動画のアップロード中にエラーが発生しました。エラー:' + e.message);
    } finally {
      this.imageUploadingProgress = false;
    }
  }

  appendMarkdownMarkerEffect(markdown: string) {
    const calculateFastMatterLines = (string: string) => {
      const fastMatterRegExp = new RegExp('^---[\\s\\S]*?---');
      const match = string.match(fastMatterRegExp);
      if (!match || !match[0]) {
        return 0;
      }

      return match[0].split('\n').length;
    };

    const previewResult = document.getElementsByClassName('kamigame-editor-contents')[0] as HTMLElement;
    if (previewResult) {
      previewResult.dataset.fastmatterLines = String(calculateFastMatterLines(markdown));
    }
    document.querySelectorAll(':not(table) *[data-md-block]').forEach((block) => {
      if (!(block instanceof HTMLElement)) {
        return;
      }

      bindToBlockElement(this.codeMirror, block, previewResult);
    });

    document.querySelectorAll('table[data-md-block]').forEach((table) => {
      const previewResult = document.getElementsByClassName('kamigame-editor-contents')[0] as HTMLElement;
      if (!(table instanceof HTMLTableElement)) {
        return;
      }

      bindToTableElement(this.codeMirror, table, previewResult);
    });
  }

  onWikiPagePartialSelected(pagePartial: components['schemas']['v1WikiPagePartialName']) {
    this.editor.insertText(`{template ${pagePartial.name}}`);
  }
  onWikiPageTemplateSelected(pageTemplate: components['schemas']['v1WikiPageTemplateTitleAndBody']) {
    this.editorComponent.invoke('setMarkdown', pageTemplate.body || '');
  }
  onPartialGenerateCodeSelected(text: string) {
    this.editor.insertText(text);
  }
  onFileDrop(e: any) {
    console.log(e);
  }

  onEditorChange() {
    const markdown = this.editorComponent.invoke('getMarkdown');
    this.$emit('input', markdown);

    // デバッグ用にマークダウンの完成形を kgMdDump 変数に格納する
    (window as any).kgMdDump = this.editorConvertor?.toCompletedMarkdown(markdown);

    const render = () => {
      if (this.editorContent) {
        const { html, scripts } = this.editsWikiPage
          ? this.editorConvertor!.toWikiPageHTMLWithScripts(markdown, this.description)
          : this.editorConvertor?.toWidgetHTMLWithScripts(markdown) || { html: '', scripts: [] };
        this.editorContent.innerHTML = html;
        this.helperScripts = scripts;
      }
      this.appendMarkdownMarkerEffect(markdown);
    };

    if (this.lightModeEnabled) {
      if (this.onChangeTimeoutID) {
        window.clearTimeout(this.onChangeTimeoutID);
      }
      this.onChangeTimeoutID = setTimeout(render, LIGHT_RENDER_DELAY);
    } else {
      render();
    }
  }

  getParsedTokens() {
    return this.editorConvertor?.getParsedTokens(this.editorComponent.invoke('getMarkdown'));
  }

  getCustomTOC() {
    return this.editorConvertor?.getCustomTOC(this.editorComponent.invoke('getMarkdown'));
  }
}
